import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-128bce48"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "edit-profile flex-column-item"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "avatar-flex"
};
const _hoisted_4 = {
  class: "double"
};
const _hoisted_5 = {
  class: "item"
};
const _hoisted_6 = {
  class: "item"
};
const _hoisted_7 = {
  class: "item"
};
const _hoisted_8 = {
  class: "form-email"
};
const _hoisted_9 = {
  class: "item"
};
const _hoisted_10 = {
  class: "item"
};
const _hoisted_11 = {
  class: "item"
};
const _hoisted_12 = {
  class: "item"
};
const _hoisted_13 = {
  class: "item"
};
const _hoisted_14 = {
  class: "item"
};
const _hoisted_15 = {
  class: "item"
};
const _hoisted_16 = {
  class: "action-btn"
};
import PageTitle from '@/components/page-title';
import { reactive, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { getCurrDate } from '@/utils/data-format.js';
import { getUserInfo, saveUserInfo } from '@/service/setting/profile.js';
import { useI18n } from 'vue-i18n';
const cicleUrl = 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';
export default {
  __name: 'edit-profile',

  setup(__props) {
    const {
      t
    } = useI18n();
    const formRef = ref(null);
    const profileForm = reactive({});
    const router = useRouter();

    const handleEditTo = path => {
      router.push(path);
    };

    const handleSaveEdit = () => {
      const data = {
        birthday: profileForm.birthday,
        city: profileForm.city,
        country: profileForm.country,
        first_name: profileForm.firstname,
        gender: profileForm.gender,
        last_name: profileForm.lastname,
        tel: profileForm.tel
      };
      saveUserInfo(data).then(res => {
        if (res.code == 200) {
          ElMessage({
            message: t('profile.success_msg'),
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.message,
            type: 'warning'
          });
        }
      });
    };

    const disabledDate = val => {
      return val.getTime() > new Date().getTime();
    };

    onMounted(() => {
      getUserInfo().then(res => {
        if (res.code == 200) {
          Object.assign(profileForm, { ...JSON.parse(JSON.stringify(res.result))
          });
          profileForm.accountType = res.result.user_type == 1 ? t('profile.form.account_type.account_1_ordinary') : t('profile.form.account_type.account_2_professional');
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(PageTitle), {
        title: _ctx.$t('settingMain.menu_list.edit_profile')
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: profileForm,
        "label-position": "top",
        "label-width": "100px",
        "hide-required-asterisk": "",
        style: {
          "width": "100%"
        },
        size: "large"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.avatar'),
          prop: "oldPass",
          class: "avatar-form-item"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_avatar, {
            size: "large",
            src: cicleUrl
          })])]),
          _: 1
        }, 8, ["label"]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.username'),
          prop: "oldPass"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => profileForm.username = $event),
            disabled: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.account_type.account_title'),
          prop: "accountType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.accountType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => profileForm.accountType = $event),
            disabled: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.form.email')), 1), _createElementVNode("span", {
          class: "email-action",
          onClick: _cache[2] || (_cache[2] = $event => handleEditTo('/setting/email'))
        }, _toDisplayString(_ctx.$t('profile.form.bind_email_btn')), 1)]), _createVNode(_component_el_form_item, {
          prop: "email"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.email,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => profileForm.email = $event),
            disabled: ""
          }, null, 8, ["modelValue"])]),
          _: 1
        })])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.phone')
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.tel,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => profileForm.tel = $event),
            placeholder: _ctx.$t('profile.form.phone_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.first_name'),
          prop: "lastname"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.lastname,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => profileForm.lastname = $event),
            placeholder: _ctx.$t('profile.form.first_name_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.last_name'),
          prop: "firstname"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.firstname,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => profileForm.firstname = $event),
            placeholder: _ctx.$t('profile.form.last_name_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.birthday'),
          prop: "Birthday"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: profileForm.birthday,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => profileForm.birthday = $event),
            type: "date",
            placeholder: _unref(getCurrDate)(),
            format: "YYYY-MM-DD",
            "value-format": "YYYY-MM-DD",
            "disabled-date": disabledDate
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.gender'),
          prop: "sex"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_group, {
            modelValue: profileForm.gender,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => profileForm.gender = $event)
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio, {
              label: 1
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('profile.form.male')), 1)]),
              _: 1
            }), _createVNode(_component_el_radio, {
              label: 2
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('profile.form.female')), 1)]),
              _: 1
            })]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.country'),
          prop: "Country"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.country,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => profileForm.country = $event),
            placeholder: _ctx.$t('profile.form.country_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_el_form_item, {
          label: _ctx.$t('profile.form.city'),
          prop: "City"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: profileForm.city,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => profileForm.city = $event),
            placeholder: _ctx.$t('profile.form.city_hint')
          }, null, 8, ["modelValue", "placeholder"])]),
          _: 1
        }, 8, ["label"])])])]),
        _: 1
      }, 8, ["model"]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleSaveEdit
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('profile.submit_btn')), 1)]),
        _: 1
      })])])]);
    };
  }

};