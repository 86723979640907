import request from '@/service/index'

const profileApi = {
  getUserInfo: '/portal/setting/profile',
  saveUserInfo: '/portal/setting/profile'
}

export function getUserInfo(abc) {
  return request.get({
    url: profileApi.getUserInfo
  })
}

export function saveUserInfo(data) {
  return request.put({
    url: profileApi.saveUserInfo,
    data
  })
}
